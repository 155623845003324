import NextLink from 'next/link';
import {useRouter} from 'next/router';
import {Flex, type FlexProps, Text} from '@chakra-ui/layout';
import {Button} from '@chakra-ui/button';
import {ArrowBackIcon} from '@chakra-ui/icons';

import {useTranslate} from '@eksab/i18n';
import {Image} from '@eksab/components/Image';
import {getLanguage} from '@eksab/util';

import kataflamLogo from './kataflam.webp';
import triviaSportsIllustration from './trivia-sports-illustration.webp';
import fantasyIllustration from './fantasy-illustration.webp';
import tacticIllustration from './tactic-illustration.webp';

interface GameCardProps extends FlexProps {
  game: 'trivia-sports' | 'trivia-art' | 'fantasy' | 'tactic';
}

const illustrationMap = {
  'trivia-sports': triviaSportsIllustration,
  'trivia-art': kataflamLogo,
  fantasy: fantasyIllustration,
  tactic: tacticIllustration,
};

export const GameCard = ({game, ...props}: GameCardProps) => {
  const {locale} = useRouter();

  const t = useTranslate();

  return (
    <Flex
      flexDir={{base: 'column-reverse', lg: 'row'}}
      align="center"
      justify={{base: 'flex-start', lg: 'space-between'}}
      gap={[4, 6, 8]}
      px={[4, 8, 20, 28]}
      py={[8, 9, 10]}
      bg="white"
      borderWidth="1px"
      borderColor="rgb(144 175 219 / 0.3)"
      rounded={['1rem', null, '2rem']}
      _even={{flexDir: {base: 'column-reverse', lg: 'row-reverse'}}}
      {...props}
    >
      <Flex flexDir="column" align={{base: 'center', lg: 'flex-start'}} textAlign={{base: 'center', lg: 'start'}}>
        <Text as="h3" fontSize={['md', 'lg', 'xl', '2xl']} fontWeight="800" color="green.400" mb={{base: 1, lg: 0}}>
          {t(`home.games.${game}.heading`)}
        </Text>

        <Text fontSize={['sm', 'lg', 'xl', '2rem']} fontWeight="600" color="gray.900" mb={[1, 2, 4]}>
          {t(`home.games.${game}.subheading`)}
        </Text>

        <Text fontSize={['xs', 'sm', 'md']} fontWeight="500" color="#727f94" maxW="30rem">
          {t(`home.games.${game}.description`)}
        </Text>

        <Button
          as={NextLink}
          href={
            game === 'trivia-art'
              ? '/kataflam'
              : game === 'trivia-sports'
                ? '/trivia/sports'
                : game === 'tactic'
                  ? '/predictions'
                  : `/${game}`
          }
          mt={[2, 3, 4, 6]}
          gap="3"
          fontWeight="700"
          fontSize={['xs', 'sm', null, 'md']}
          lineHeight="1.5"
          h="unset"
          ps={[3.5, 5, 6]}
          pe={[2, 3, 4]}
          py={[2.5, 3, 4]}
        >
          {t('app.actions.play.now')}

          <ArrowBackIcon w={[2, 3, 4]} h="auto" transform="auto" scaleX={getLanguage(locale) === 'ar' ? 1 : -1} />
        </Button>
      </Flex>

      <Image
        src={illustrationMap[game]}
        alt={t(`home.games.${game}.heading`)}
        height={280}
        w="auto"
        h="full"
        maxH="clamp(9.5rem, 20vw, 18rem)"
        objectFit="contain"
      />
    </Flex>
  );
};
