import {useEffect, useRef} from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import {GetStaticProps} from 'next/types';
import {dehydrate, QueryClient} from '@tanstack/react-query';

import {config} from '@eksab/config';
import {useTranslate} from '@eksab/i18n';
import {useAppSettingsQuery} from '@eksab/hooks/useAppSettingsQuery';
import {useRegionSettings} from '@eksab/hooks/useRegionSettings';

import {Hero} from '@eksab/features/home/hero/Hero';
import {FeaturedIn} from '@eksab/features/home/featured-in/FeaturedIn';
import {OurGames} from '@eksab/features/home/games/OurGames';
import {useWinnersQuery} from '@eksab/features/home/winners/useWinnersQuery';
import {useFeaturedPackagesQuery} from '@eksab/features/home/coins/useFeaturedPackagesQuery';

const EndorsedBy = dynamic(() => import('@eksab/features/home/endorsements/EndorsedBy').then((mod) => mod.EndorsedBy), {
  loading: () => <div>Loading...</div>,
});

const Winners = dynamic(() => import('@eksab/features/home/winners/Winners').then((mod) => mod.Winners), {
  loading: () => <div>Loading...</div>,
});

const BuyCoins = dynamic(() => import('@eksab/features/home/coins/BuyCoins').then((mod) => mod.BuyCoins), {
  loading: () => <div>Loading...</div>,
});

export default function Home() {
  const ourGamesRef = useRef<HTMLDivElement>(null);

  const t = useTranslate();

  useEffect(() => {
    mixpanel?.track('Page View', {page: 'Home'});
  }, []);

  const onShowGames = () => {
    ourGamesRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <>
      <Head>
        <meta name="facebook-domain-verification" content={config.fbDomainVerificationCode} />
        <meta name="description" content="Predict game score and win prizes | Eksab" />
        <title>{`${t('app.home')} | ${t('app.name')}`}</title>
      </Head>

      <Hero mt="clamp(0rem, calc(2.5vmax - 0.5rem), 2rem)" onCTA={onShowGames} />

      <FeaturedIn mt={[6, 10, 12, 16]} mb={[16, 24, 32]} />

      <OurGames ref={ourGamesRef} mb={[6, 10, 16, 24]} />

      <EndorsedBy mb={[6, 10, 16, 24]} />

      <Winners mb={[6, 10, 16, 24]} />

      <BuyCoins mb={[6, 10, 16, 24]} />
    </>
  );
}

export const getStaticProps: GetStaticProps = async ({locale}) => {
  try {
    const queryClient = new QueryClient();

    await Promise.all([
      queryClient.prefetchQuery([useRegionSettings.queryKey, locale], useRegionSettings.queryFn),
      queryClient.prefetchQuery([...useAppSettingsQuery.queryKey, locale], useAppSettingsQuery.queryFn),
      queryClient.prefetchQuery(useWinnersQuery.queryKey, useWinnersQuery.queryFn),
      queryClient.prefetchQuery(useFeaturedPackagesQuery.queryKey, useFeaturedPackagesQuery.queryFn),
    ]);

    return {
      props: {
        dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
      },
      revalidate: 60 * 60 * 1,
    };
  } catch (e) {
    return {props: {}, revalidate: 60};
  }
};
